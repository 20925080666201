<template>
	<div class="formWrap">
		<el-form :model="formModel" ref="configForm" class="formClass">
			<el-row>
				<template v-for="it in items">
					<el-col :key="it.key" :span="it.span || 24" v-if="showCol(it)" :style="it.style || ''">
						<el-form-item
							:label="it.label || ' '"
							:prop="it.key"
							:rules="getRulesByItem(it)"
							:label-width="it.labelWidth || '110px'"
							:style="`${it.formItemStyle || ''} `"
							:class="`${it.formItemClass || ''}`"
						>
							<template v-slot:label v-if="it.labelRender">
								<Render :render="() => it.labelRender(formModel, it, form)" />
							</template>
							<template v-slot:label v-else-if="it.labelPosition || it.labelStyle">
								<div :style="`text-align: ${it.labelPosition || 'left'};${it.labelStyle || ''} `">{{ it.label }}</div>
							</template>
							<el-input
								v-model="formModel[it.key]"
								size="small"
								v-if="it.type === 'input'"
								:clearable="it.clearable !== false"
								:placeholder="it.placeholder || '请输入'"
								:readonly="isView"
								:disabled="getDisabledByItem(it)"
								@change="(e) => valueChange(e, it)"
							/>
							<el-input
								v-model="formModel[it.key]"
								size="small"
								v-else-if="it.type === 'textarea'"
								:clearable="it.clearable !== false"
								type="textarea"
								:rows="it.rows || 2"
								:placeholder="it.placeholder || '请输入内容'"
								:autosize="it.autosize !== false"
								:readonly="it.readOnly || isView"
								:disabled="getDisabledByItem(it)"
								maxlength="1000"
							/>
							<el-input
								size="small"
								v-else-if="it.type === 'empty'"
								style="visibility: hidden"
								:readonly="isView"
								:disabled="it.disabled"
							/>
							<el-input-number
								v-model="formModel[it.key]"
								size="small"
								v-else-if="it.type === 'inputNumber' && !isView"
								:clearable="it.clearable !== false"
								style="width: 100%"
								:placeholder="it.placeholder || '请输入'"
								:precision="it.precision === 0 || it.precision ? it.precision : 2"
								:step="it.step"
								:max="it.max"
								:min="it.min"
								controls-position="right"
								:readonly="isView"
								:disabled="it.disabled"
								@change="(e) => valueChange(e, it)"
								class="content_inputNumber"
							></el-input-number>
							<el-select
								v-else-if="getRealType(it.type) === 'select' && !isView"
								:value="formModel[it.showKey] || formModel[it.key]"
								@change="(e) => selectValueChange(e, it)"
								size="small"
								:clearable="it.clearable !== false"
								filterable
								style="width: 100%"
								:placeholder="it.placeholder || '请选择'"
								:multiple="it.multiple || false"
								:allow-create="it.allowCreate || false"
								:default-first-option="it.defaultFirstOption || false"
								:disabled="getDisabledByItem(it) || isView"
								:class="it.class || ''"
							>
								<el-option v-for="item in getOpts(it)" :key="item.dictId" :value="item.dictId" :label="item.dictName">
									<template v-if="it.optEdit">
										<div v-if="item.footerRender" @click="(e) => e.stopPropagation()">
											<i class="el-icon-plus" style="color: #409eff" @click="() => handleAddOpt(item, it)"></i>
										</div>
										<div v-else style="display: inline-flex; align-items: center; justify-content: space-between; width: 100%">
											<span style="margin-right: 10px">{{ item.dictName }}</span>
											<span>
												<i
													v-if="item.type !== 1"
													class="el-icon-edit"
													style="color: #409eff; margin-right: 10px"
													@click="(e) => editOpt(e, item, it)"
												></i>
												<i
													v-if="item.type !== 1"
													class="el-icon-delete"
													style="color: #f56c6c"
													@click="(e) => delOpt(e, item, it)"
												></i>
											</span>
										</div>
										<!-- <Render :render="() => it.optRender(item, formModel, getOpts(it))" /> -->
									</template>
								</el-option>
							</el-select>
							<el-radio-group
								v-model="formModel[it.key]"
								size="small"
								v-else-if="getRealType(it.type) === 'radio' && !isView"
								@change="(e) => valueChange(e, it)"
							>
								<el-radio v-for="item in getOpts(it)" :key="item.dictId" :label="item.dictId" :disabled="item.disabled">{{
									item.dictName
								}}</el-radio>
							</el-radio-group>
							<el-checkbox v-else-if="getRealType(it.type) === 'checkbox' && !isView" v-model="formModel[it.key]">{{
								it.text
							}}</el-checkbox>
							<el-select
								v-else-if="it.type === 'selectSearch' && !isView"
								:value="formModel[it.showKey] || formModel[it.key]"
								@change="(e) => selectValueChange(e, it)"
								size="small"
								:multiple="it.multiple || false"
								filterable
								remote
								reserve-keyword
								placeholder="请输入关键词"
								:remote-method="(e) => remoteMethod(e, it)"
								:loading="loading"
								clearable
								style="width: 100%"
								:readonly="isView"
								:disabled="getDisabledByItem(it)"
							>
								<el-option v-for="item in getOpts(it)" :key="item.dictId" :value="item.dictId" :label="item.dictName"> </el-option>
							</el-select>
							<el-popover
								placement="bottom-start"
								trigger="click"
								v-else-if="getRealType(it.type) === 'tree' && !isView"
								:disabled="it.disabled"
							>
								<el-tree
									:data="treeData || getOpts(it)"
									:node-key="it.nodeKey"
									:props="it.props"
									:ref="'tree' + it.key"
									:show-checkbox="it.multiple"
									default-expand-all
									@check="(_, checkKey) => treeCheck(it, { ..._, ...checkKey })"
									@node-click="(d) => treeNodeClick(it, d)"
								></el-tree>

								<el-input
									slot="reference"
									v-model="formModel[it.showKey || it.key]"
									size="small"
									style="width: 100%"
									placeholder="请选择"
									:clearable="it.clearable !== false"
									@clear="(e) => clearTreeInput(e, it)"
									@focus="(e) => handleTreeInput(e, it)"
									@input="(e) => treeChange(e, it)"
									:disabled="it.disabled"
								></el-input>
							</el-popover>
							<el-date-picker
								v-else-if="it.type === 'date' && !isView"
								v-model="formModel[it.key]"
								value-format="yyyy-MM-dd"
								format="yyyy-MM-dd"
								size="small"
								placeholder="选择时间"
								:clearable="it.clearable !== false"
								style="width: 100%"
								:readonly="isView"
								:disabled="it.disabled"
							></el-date-picker>
							<el-date-picker
								v-else-if="it.type === 'year' && !isView"
								v-model="formModel[it.key]"
								type="year"
								value-format="yyyy"
								format="yyyy"
								placeholder="选择年"
								:clearable="it.clearable !== false"
								size="small"
								style="width: 100%"
								:readonly="isView"
								:disabled="it.disabled"
							>
							</el-date-picker>
							<el-date-picker
								v-if="it.type === 'daterange'"
								v-model="formModel[it.key]"
								type="daterange"
								size="small"
								style="width: 100%"
								range-separator="至"
								start-placeholder="开始日期"
								end-placeholder="结束日期"
								value-format="yyyy-MM-dd"
								:clearable="it.clearable !== false"
								:readonly="isView"
								:disabled="it.disabled"
							>
							</el-date-picker>
							<!-- 年度范围选择 -->
							<YearRange
								v-else-if="it.type === 'yearrange' && !isView"
								:value="formModel[it.key]"
								@input="(e) => yearRangeChange(e, it)"
								sp="-"
								:clearable="it.clearable === false ? false : true"
							></YearRange>
							<el-cascader
								v-else-if="it.type === 'cascader'"
								ref="cascaderLinkage"
								:show-all-levels="it.showAllLevels !== false"
								size="small"
								v-model="formModel[it.key]"
								:options="getOpts(it)"
								:props="it.props"
								clearable
								style="width: 100%"
								:placeholder="it.placeholder || '请选择'"
								@change="(e) => valueChange(e, it)"
								:disabled="isView"
							></el-cascader>
							<div v-else-if="it.render" :style="it.style">
								<Render :render="() => it.render(formModel, it, form)" />
							</div>
							<el-input
								size="small"
								:value="
									getOpts(it)?.find(({ dictId }) => dictId === formModel[it.key])?.dictName ||
									formModel[it.showKey] ||
									formModel[it.key]
								"
								:title="
									getOpts(it)?.find(({ dictId }) => dictId === formModel[it.key])?.dictName ||
									formModel[it.showKey] ||
									formModel[it.key]
								"
								v-else-if="['tree', 'select', 'selectSearch', 'inputNumber', 'date', 'year'].includes(getRealType(it.type)) && isView"
								readonly
							></el-input>
							<template v-else-if="it.type === 'person'">
								<el-tag
									class="tag-gap"
									:closable="!isView"
									v-for="(tag, i) in formModel[it.key]"
									@close="removeStaff(i, it.key)"
									:key="tag.staffId"
									:style="`background: ${tag.isOrg ? '#a1ecbe' : ''}`"
									>{{ tag.name || tag.staffName }}</el-tag
								>
								<departments
									style="width: 100%"
									btnText="添加"
									v-model="formModel[it.key]"
									@updateStaffTag="(e) => addApprovalStaffs(e, it.key)"
									@updateTag="(e) => updateTag(e, it.key)"
									:setChecks="setChecks"
									:show-checkbox="it.showCheckbox"
									:show-staff="it.showStaff"
									:queryType="it.queryType || '1'"
									ref="stafftree"
									v-show="!isView"
								></departments>
							</template>
							<Ckeditor4
								v-else-if="it.type === 'ckeditor'"
								v-model="formModel[it.key]"
								@input="(e) => valueChange(e, it)"
								:readonly="isView"
							/>
							<Upload
								v-else-if="it.type === 'upload'"
								v-model="formModel[it.key]"
								@change="(e) => uploadChange(e, it)"
								:limit="1"
								:img2base64="it.img2base64"
							/>
							<!-- <InputWidgets :isView="isView" :formModel="formModel" :item="it" :opts="opts" /> -->
						</el-form-item>
					</el-col>
				</template>
			</el-row>
		</el-form>
		<slot> </slot>
		<div class="dialog-footer" v-if="showFooter && !isView">
			<el-button @click="$emit('cancel')">取 消</el-button>
			<el-button type="primary" @click="submitForm">确 定</el-button>
		</div>
		<!-- 添加下拉 -->
		<el-dialog
			:title="dialogAddOptTitle"
			top="30vh"
			center
			:visible.sync="dialogAddOptVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			append-to-body
			width="600px"
			@opened="dialogOpened"
			custom-class="dialogAdd"
		>
			<el-form
				ref="addOptForm"
				:model="addOptModel"
				:rules="{ name: [{ required: true, message: '请输入', trigger: 'blur' }] }"
				label-width="120px"
			>
				<el-form-item :label="dialogAddOptModel.label" :prop="dialogAddOptModel.optProps?.key">
					<el-input ref="addOptInput" size="small" v-model="addOptModel[dialogAddOptModel.optProps?.key]"></el-input>
				</el-form-item>
				<div style="text-align: center">
					<el-button @click="dialogAddOptVisible = false">取 消</el-button>
					<el-button type="primary" @click="submitAddOptForm">确 定</el-button>
				</div>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
import Render from '../components-base-table/render.vue';
import Departments from '@/components/departs';
import { transfromTreeData } from '@/utils/util';
import YearRange from '../components-year-range/index.vue';
import Ckeditor4 from '../components-ckeditor4';
import Upload from '@/views/components/components-upload/index.vue';

// import InputWidgets from './inputWidgets';
export default {
	components: { Render, Departments, YearRange, Ckeditor4, Upload },
	name: 'componentsFormItem',
	props: {
		formItems: { type: Array, default: () => [], required: true },
		defaultData: { type: Object, required: false },
		showFooter: { type: Boolean, default: () => true, required: false },
		type: { type: String, required: false }
	},
	computed: {
		getOptsById() {
			return (id) => this.$store.state.app?.dict?.filter((item) => item.groupId === id) || [];
		},
		form() {
			return this.$refs.configForm;
		},
		storeOptions() {
			return this.$store.state.setData.options;
		},
		getOpts() {
			return ({ groupId, options, key, footerRender, formatOpts, filterOpts }) => {
				let opts = [];

				if (options) {
					opts = options;
					if (typeof options === 'function') {
						opts = options(this.opts, this.formModel);
					}
					if (!opts?.length) {
						const opt = this.$store.state.setData.options?.[key];

						opts = opt || [];
					}
					// this.opts[key] = opts;
				} else if (this.opts[key]) {
					const opt = this.$store.state.setData.options?.[key];

					opts = opt || this.opts[key];
					this.opts[key] = opts;
				} else if (this.storeOptions[key]?.length) {
					opts = this.storeOptions[key];
				}
				if (groupId) {
					opts = this.getOptsById(groupId);
					this.opts[key] = opts;
				}
				if (footerRender) {
					opts = [...opts, { footerRender, dictId: Math.random() }];
					// this.opts[key] = opts;
				}
				if (formatOpts && typeof formatOpts === 'function') {
					opts = opts.map((it) => formatOpts(it));
					// this.opts[key] = opts;
				}
				if (filterOpts && typeof filterOpts === 'function') {
					opts = filterOpts(opts, this.formModel);
					// this.opts[key] = opts;
				}

				return opts;
			};
		},
		setChecks() {
			return this.selectedStaffs.map(({ id, staffId }) => id || staffId);
		},
		// 展示模式
		isView() {
			return this.type === 'view';
		},
		treeListData() {
			return ({ groupId, options, key, props }) => transfromTreeData(this.getOpts({ groupId, options, key }), props.children);
		}
	},
	watch: {
		formItems: {
			deep: true,
			handler(val) {
				if (val && val.length) {
					this.items = val;
					val.filter((val) => Array.isArray(val.options)).forEach(({ key, options }) => {
						this.opts[key] = options;
					});
				}
			}
		},
		defaultData: {
			deep: true,
			handler(val, old) {
				if (val) {
					this.formModel = this.defaultData;
					if (JSON.stringify(val) === '{}' && JSON.stringify(old) !== '{}') {
						setTimeout(() => {
							// this.form.resetFields();
							this.form.clearValidate();
						}, 0);
						return;
					}
					if (old) {
						Object.keys(old).forEach((key) => {
							// eslint-disable-next-line no-void
							if (old[key] != void 0 && !(val[key] != void 0)) {
								setTimeout(() => {
									this.form.clearValidate(key);
								}, 0);
							}
						});
					}
				}
			}
		},
		opts: {
			deep: true,
			handler(val) {
				const { state, commit } = this.$store;

				commit('SET_OPTIONS', { ...state.setData.options, ...val });
			}
		}
		// items: {
		// 	deep: true,
		// 	handler(val) {
		// 		if (val && val.length) {
		// 			val.forEach((it) => {
		// 				if(it.type === 'list'){

		// 				}
		// 			});
		// 		}
		// 	}
		// }
	},
	data() {
		return {
			formModel: {},
			items: [],
			opts: {},
			loading: false,
			selectedStaffs: [],
			treeData: null,
			dialogAddOptVisible: false,
			dialogAddOptModel: {},
			dialogAddOptTitle: '新增',
			formAddOptItem: {},
			addOptValue: '',
			addOptModel: {}
		};
	},

	mounted() {
		this.items = this.formItems;
		if (this.defaultData) {
			this.formModel = this.defaultData;
		}
		const fetchList = [];

		this.formItems
			.filter((val) => val.async && (val.type === 'selectSearch' ? val.firstRequest : true))
			.forEach((item) => {
				fetchList.push(item);
			});
		Promise.all(
			fetchList.map((it) => {
				if (it.onlyOnceRequest) {
					const opts = this.$store.state.setData.options;

					if (opts[it.key]?.length) {
						return Promise.resolve(opts[it.key]);
					}
				}
				return it.async();
			})
		).then((res) => {
			if (res && res.length) {
				const params = {};

				fetchList.forEach(({ key }, inx) => {
					params[key] = res[inx] || [];
					this.$set(this.opts, [key], res[inx] || []);
				});
				// this.opts = { ...this.opts, ...params };
			}
		});
	},

	methods: {
		submitForm() {
			this.$emit('submit', this.$refs.configForm, this.formModel);
		},
		showCol({ hidden }) {
			if (typeof hidden === 'function') {
				return !hidden(this.formModel, this.opts);
			}
			return !hidden;
		},
		uploadChange(e, { key }) {
			this.$set(this.formModel, key, e);
		},
		valueChange(e, { change, key, showKey, type }) {
			if (showKey && this.getRealType(type) !== 'tree') {
				const opt = this.opts[key] || this.storeOptions[key] || [];

				this.$set(this.formModel, showKey, opt.find(({ dictId }) => e === dictId)?.dictName);
			}
			if (change) {
				change(
					this.formModel,
					this.opts[key]?.find(({ dictId }) => e === dictId),
					this.items
				);
			}
		},
		remoteMethod(query, { async, key }) {
			this.loading = true;
			async(query).then((res) => {
				this.loading = false;
				this.opts[key] = res;
			});
		},
		// 勾选组织
		updateTag(staffs, key) {
			this.selectedStaffs = [
				...staffs?.map(({ orgName, id, orgPid }) => ({ name: orgName, id, staffId: id, pId: orgPid, isOrg: true }))
				// ...this.selectedStaffs.filter((it) => it.isOrg)
			];
			this.$set(this.formModel, key, this.selectedStaffs);
			this.$refs.configForm.validateField(key);
		},
		// 添加人员
		addApprovalStaffs(staffs, key) {
			this.selectedStaffs = [
				...staffs?.map(({ staffName, staffId }) => ({ name: staffName, staffName, staffId })),
				...this.selectedStaffs.filter((it) => !it.isOrg)
			];
			this.$set(this.formModel, key, this.selectedStaffs);
			this.$refs.configForm.validateField(key);
		},
		/* 获得所有pid */
		getPids({ pId }) {
			const pNode = this.selectedStaffs.find(({ id }) => pId === id);

			let arr = [];

			if (pNode) {
				arr = arr.concat([pId, ...this.getPids(pNode)]);
			} else {
				return arr;
			}
			return arr;
		},
		/* 获得所有子id */
		getChildren({ id }) {
			const cNodes = this.selectedStaffs.filter(({ pId }) => pId === id);

			let arr = [];

			if (cNodes && cNodes.length) {
				cNodes.forEach((item) => {
					arr = arr.concat([...cNodes.map(({ id }) => id), ...this.getChildren(item)]);
				});
			} else {
				return arr;
			}
			return arr;
		},
		// 删除对象
		removeStaff(index, key) {
			const delStaff = this.selectedStaffs[index];
			const delStafFn = () => {
				this.selectedStaffs.splice(index, 1);
				if (delStaff.isOrg) {
					const pids = this.getPids(delStaff);
					const children = this.getChildren(delStaff);

					this.selectedStaffs = this.selectedStaffs.filter((it) => ![...pids, ...children].includes(it.staffId));
				}
				this.$set(this.formModel, key, this.selectedStaffs);
				this.$refs.configForm.validateField(key);
			};

			delStafFn();
		},
		// showKey逻辑
		selectValueChange(e, { key, showKey, change }) {
			this.$set(this.formModel, key, e);
			if (showKey) {
				this.$set(this.formModel, showKey, '');
			}
			this.valueChange(e, { change, key, showKey });
		},
		// handleTreeInput
		handleTreeInput(e, it) {
			if (this.$refs[`tree${it.key}`] && this.$refs[`tree${it.key}`][0] && it.multiple) {
				if (Array.isArray(this.formModel[it.key])) {
					this.$refs[`tree${it.key}`][0].setCheckedKeys(this.formModel[it.key]);
				}
			}
		},
		// tree 勾选
		treeCheck(item, { checkedNodes, checkedKeys }) {
			if (item.multiple) {
				if (item.nodeName) {
					this.$set(this.formModel, item.showKey, checkedNodes.map((data) => data[item.nodeName]).join(','));
				}
				this.$set(this.formModel, item.key, checkedKeys.join(','));
				this.form.validateField(item.key);
				this.valueChange(null, item);
			}
		},
		// tree  node点击
		treeNodeClick(item, data) {
			if (item.multiple) {
				return;
			}
			if (item.showKey) {
				this.$set(this.formModel, item.showKey, data[item.nodeName]);
			}
			this.$set(this.formModel, item.key, data[item.nodeKey]);
			this.$nextTick(() => {
				document.querySelector('div').click();
			});
			this.valueChange(null, item);
		},
		// 清除
		clearTreeInput(e, item) {
			this.$nextTick(() => {
				this.$set(this.formModel, item.showKey, undefined);
				this.$set(this.formModel, item.key, undefined);
				this.$refs[`tree${item.key}`][0].setCheckedKeys([]);
				this.form.validateField(item.key);
				if (item.change) {
					item.change(
						this.formModel,
						this.opts[item.key]?.find(({ dictId }) => e === dictId),
						this.items
					);
				}
			});
		},
		// yearRangeChange
		yearRangeChange(e, item) {
			this.$set(this.formModel, item.key, e);
			if (e) {
				this.form.validateField(item.key);
			}
		},
		// treechange
		treeChange(e, { key, props, filter, groupId, options }) {
			if (!filter) {
				return;
			}
			if (e) {
				// 过滤
				this.treeData = this.treeListData({ groupId, options, key, props }).filter((it) => it[props.label].includes(e));
			} else {
				this.treeData = null;
			}
		},
		// getRealType
		getRealType(type) {
			if (typeof type === 'function') {
				return type(this.formModel);
			}
			return type;
		},
		// 点击新增
		handleAddOpt(opt, item) {
			this.dialogAddOptModel = { ...item };
			this.dialogAddOptTitle = '新增';
			this.addOptModel = {};
			document.querySelector(`.${item.class}`).click();
			this.dialogAddOptVisible = true;
		},
		// 编辑下拉框
		editOpt(e, data, item) {
			e.stopPropagation();
			this.dialogAddOptModel = { ...item };
			this.addOptModel = { [item.optProps?.key]: data.dictName, id: data.dictId };
			this.dialogAddOptTitle = '修改';
			document.querySelector(`.${item.class}`).click();
			this.dialogAddOptVisible = true;
		},
		// 删除下拉框
		delOpt(e, data, item) {
			e.stopPropagation();
			this.$confirm('是否确定删除?', '删除提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				const { optProps, key, class: className } = item;

				this.$axios
					.post(optProps.del, {
						request: { id: data.dictId, type: data.type }
					})
					.then((res) => {
						if (res && res.code === 0) {
							this.$message.success('删除成功!');
							const { state, commit } = this.$store;
							const opts = state.setData.options?.[key] || [];
							const resOtps = opts.filter(({ dictId }) => dictId !== data.dictId);

							commit('SET_OPTIONS', { ...state.setData.options, [key]: resOtps });
							// this.opts[key] = resOtps;
							document.querySelector(`.${className}`).click();
						}
					});
			});
		},
		// 添加下拉提交
		submitAddOptForm() {
			this.$refs.addOptForm.validate((valid) => {
				if (valid) {
					const { optProps, key, class: className } = this.dialogAddOptModel;

					this.$axios
						.post(optProps.add, { request: { ...this.addOptModel } })
						.then((res) => {
							if (res.code) {
								return;
							}
							this.dialogAddOptVisible = false;
							this.$message.success('保存成功');

							const { state, commit } = this.$store;

							const opts = state.setData.options?.[key] || [];
							const { type, id, name } = res;

							let resOtps = [];

							if (this.addOptModel?.id) {
								// 编辑
								resOtps = opts.map((val) => {
									if (this.addOptModel.id === val.dictId) {
										return { ...val, dictName: this.addOptModel[optProps.key] };
									}
									return val;
								});
							} else {
								// 新增
								resOtps = opts.concat({ type, dictId: id, dictName: name });
							}

							// this.opts[key] = resOtps;
							commit('SET_OPTIONS', { ...state.setData.options, [key]: resOtps });
							document.querySelector(`.${className}`).click();
							this.dialogAddOptModel = {};
							this.addOptModel = {};
						})
						.catch(() => {
							this.dialogAddOptVisible = false;
						});
				}
			});
		},
		dialogOpened() {
			this.$refs.addOptInput?.focus();
		},
		getDisabledByItem(it) {
			if (it.disabled && typeof it.disabled === 'function') {
				return it.disabled(this.formModel);
			}
			return it.disabled;
		},
		getRulesByItem(it) {
			if (it.rules && typeof it.rules === 'function') {
				return it.rules(this.formModel, it);
			}
			return it.rules;
		}
	}
};
</script>

<style lang="scss" scoped>
.formWrap {
	.dialog-footer {
		text-align: center;
	}
	.formClass {
		// min-height: 200px;
	}
}
</style>
<style lang="scss">
.app-container {
	// background-color: #fff !important;
}
.formClass {
	.el-form-item {
		.el-form-item__content {
			.el-textarea {
				padding-bottom: 4px;
				textarea {
					min-height: 32px !important;
				}
			}
		}
	}
	.el-form-item__label {
		line-height: 16px;
		padding: 0 12px 0 10px;
		margin-top: 10px;
		height: 30px;
	}
	.tag-gap {
		margin-right: 10px;
	}
	.el-input__icon {
		// line-height: inherit;
	}
	.el-cascader--small {
		line-height: 40px;
	}
	.content_inputNumber {
		margin: 4px 0;
	}
}
</style>
